<template>
  <section class="h-100">

    <div style="padding: 40px;">
      <div class="m-auto" style="display: inline-block;">
        <img
          src="@/assets/images/relatus_orange_logo_horizontal.png"
          style="max-height: 60px;"
        >
      </div>

      <div style="display: inline-block;float: right;margin-top: 13px;">
        <span class="uppercase">Pre-register</span>
        <img
          src="@/assets/images/menu.svg"
          style="display: inline-block;margin-left: 20px;"
        >
      </div>
    </div>

    <div class="flex h-screen text-center" style="height:80vh;">

      <div class="m-auto pb-10">

        <div class="mb-12 relative">
          <router-link
            :to="{name: 'registration.v2.signup.phone'}"
            class="absolute left-0"
          >
            <img
              src="@/assets/images/icons/ic_chevron_left.svg"
              class="inline-block"
            > Back
          </router-link>

          <span></span>
        </div>

        <div class="mt-9">
          <h3 class="text text-center text-neutral mb-9">
            Welcome Back!
          </h3>

          <form
            class="mt-10 mb-20"
            @submit.prevent="register"
          >

            <h3 class="text text-center text-black mb-10">
              What are your credentials?
            </h3>

            <div
              v-if="errorMessage"
              class="flex items-center text-dark text-sm mb-5 alert-error"
              role="alert"
            >
              <img
                src="@/assets/images/ic_danger.svg"
                class="mr-2"
              >
              <p>{{ errorMessage }}</p>
            </div>

            <div class="mb-3">
              <input
                v-model="emailAddress"
                type="text"
                class="form-control w-11/12"
                placeholder="Email Address or Phone Number"
                required
              >
            </div>

            <div class="mb-3">
              <input
                v-model="password"
                type="password"
                class="form-control w-11/12 mt-3"
                placeholder="Create Password"
                required
              >
            </div>

            <div class="mb-3">
              <input
                v-model="password2"
                type="password"
                class="form-control w-11/12 mt-3"
                placeholder="Repeat Password"
                required
              >
            </div>

            <p class="m-auto mt-5 max-w-sm text-left">
              <a href="#" class="text-primary font-bold">Forgot username or password</a>
            </p>

            <div class="mt-10 pt-10 text-center">
              <button
                type="submit"
                @click.prevent="submit()"
                class="btn btn-primary inline-block w-2/5" :class="{ 'btn-primary-outline': !emailAddress }"
              >
                Onwards
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SignupPassword',
  data() {
    return {
      emailAddress: '',
      password: '',
      password2: '',
      errorMessage: null
    };
  },
  methods: {
    submit() {
      if (!this.emailAddress || this.emailAddress === "") {
        this.errorMessage = 'Please fill in the email address field and try again.'
        return false;
      } else if (!this.validEmail(this.emailAddress)) {
        this.errorMessage = 'Hmm, looks like that’s an invalid email. Please try again.'
        return false;
      } else if (this.password !== this.password2) {
        this.errorMessage = 'Hmm, looks like the passwords don\'t match. Please try again.'
        return false;
      }
      this.errorMessage = null;

      let data = {
        first_name: this.firstName,
        last_name: this.lastName,
        middle_name: this.middleName,
        preferred_name: this.preferredName,
        phone_number: this.areaCode + ' ' + this.phoneNumber,
        email: this.emailAddress,
        password: this.password
      };

      axios.post(process.env.VUE_APP_API_URL + '/register', data, {
        headers: {
          'Authorization': `Bearer`
        }
      })
      .then((response) => {
        localStorage.setItem('userToken', response.data.token);

        this.$router.push('/registration/v2/official-member');
      })
      .catch((error) => {
        if (error.response) {
          this.errorMessage = error.response.data.exception;
        }
      });
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },
  mounted() {
    let firstName = localStorage.getItem('firstname');
    let lastName = localStorage.getItem('lastname');
    let middleName = localStorage.getItem('middlename');
    let preferredName = localStorage.getItem('preferredname');
    let emailAddress = localStorage.getItem('emailAddress');
    let areaCode = localStorage.getItem('areaCode');
    let phoneNumber = localStorage.getItem('phoneNumber');

    if (firstName && firstName !== '') {
      this.firstName = firstName;
    }
    if (lastName && lastName !== '') {
      this.lastName = lastName;
    }
    if (middleName && middleName !== '') {
      this.middleName = middleName;
    }
    if (preferredName && preferredName !== '') {
      this.preferredName = preferredName;
    }
    if (emailAddress && emailAddress !== '') {
      this.emailAddress = emailAddress;
    }
    if (areaCode && areaCode !== '') {
      this.areaCode = areaCode;
    }
    if (phoneNumber && phoneNumber !== '') {
      this.phoneNumber = phoneNumber;
    }
  },
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 1px;
  font-weight: bold;
}
h3 > strong {
  font-weight: bold;
}
</style>
